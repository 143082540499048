<script lang="ts" setup>
import { z } from 'zod'
const stepper = useStepper({
  'intro': {
    isValid: () => true,
  },
  'upload': {
    isValid: () => formSchema.safeParse(formModel).success,
  },
  'terms': {
    isValid: () => termsSchema.safeParse(termsModel).success,
  },
  'complete': {
    isValid: () => true,
  }
})

const formModel = reactive({
  file: ref<File | null>(null),
  url: ref<string | null>(null)
})

const termsModel = reactive({
  termsAccepted: false,
  copyrightAccepted: false,
  compensationAccepted: false
})

const handleFileUpload = (event: Event) => {
  const target = event.target as HTMLInputElement
  if (target && target.files) {
    formModel.file = target.files[0]
  }
}

// const fileSizeLimit = 5000000
const termsSchema = z.object({
  termsAccepted: z.literal(true),
  copyrightAccepted: z.literal(true),
  compensationAccepted: z.literal(true)
})
// const formSchema = z.object({
//   url: z.string().url().startsWith('https://maps.app.goo.gl/').optional(),
//   file: z.instanceof(File)
//     .refine(
//       (file: File) =>
//         [
//           'application/pdf',
//           'text/csv',
//           'application/vnd.ms-excel',
//           'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//           'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//           'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//         ].includes(file.type),
//       { message: 'Invalid document file type' }
//     )
//     .refine((file: File) => file.size <= fileSizeLimit, {
//       message: 'File size should not exceed 5MB',
//     })
//     .optional(),
// }).refine(
//   data => data.url != null || data.file != null,
//   { message: 'You must provide either a file or a Google Maps URL' }
// )

const formSchema = z.object({
  url: z.string().url().startsWith('https://maps.app.goo.gl/').nullish(),
  file: z.instanceof(File).nullish(),
}).refine(
  data => data.url != null || data.file != null,
  { message: 'You need to provide a file or a shared google map link (https://maps.app.goo.gl/1234)' }
)

const { data: userIPAddress } = await useAsyncData(() => $fetch('https://api.ipify.org?format=json'))
const supabase = useSupabaseClient()
const isLoading = ref(false)
const { showError } = useToast()
const { currentUser } = useAuth()
const handleSubmit = async () => {
  isLoading.value = true
  try {
    const { data: { session } } = await supabase.auth.getSession()
    if (!session?.access_token) {
      throw new Error('No authentication token found')
    }

    const formData = new FormData()
    if (formModel.file) formData.append('file', formModel.file)
    if (formModel.url) formData.append('url', formModel.url)

    const { data, error } = await supabase.functions.invoke('creator-application', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${session.access_token}`
      },
      body: formData
    })

    if (error) {
      console.error('Function error:', error)
      showError(error.message || 'Could not send application')
      return
    }

    const { error: termsError } = await supabase
      .from('terms_audit_log')
      .insert([{
        ip: userIPAddress.value.ip,
        terms_version: '1.0',
        user_id: currentUser.value?.user.id
      }])

    if (termsError) {
      console.error('Terms error:', termsError)
      showError('Could not save terms')
      return
    }

    stepper.goToNext()
  } catch(e) {
    console.error('Submission error:', e)
    showError('Could not complete submission: ' + (e.message || 'Unknown error'))
  } finally {
    isLoading.value = false
  }
}

const errorMessage = ref<string | null>(null)
const handleStepValidation = (message: string) => {
  errorMessage.value = null
  const validation = formSchema.safeParse(formModel)
  if (validation.success) {
    stepper.goToNext()
  } else {
    errorMessage.value = message
  }
}
</script>

<template>
  <div class="creator-onboarding">
    <ul v-if="!stepper.isCurrent('complete')" class="onboarding-dots">
      <li v-for="(step, id) in stepper.steps.value" :key="id" :class="{ 'active': !stepper.isCurrent(id) && !stepper.isBefore(id) }"/>
    </ul>
    <form @submit.prevent="handleSubmit">
      <div v-if="stepper.isCurrent('intro')" class="onboarding-step">
        <span>Creator Onboarding</span>
        <h3>Let’s discover the world together</h3>
        <p>Welcome to Mappr! Create personalized spots recommendation lists and earn recurring revenue. </p>
        <MPImg src="/media/images/creator-onboarding-intro.png" class="creator-onboarding-img"/>
        <div class="onboarding-step-actions">
          <MPButton rounded label="Create my map" version="info" size="large" @click="stepper.goToNext"/>
        </div>
      </div>
      <div v-if="stepper.isCurrent('upload')" class="onboarding-step">
        <span>Creator Onboarding</span>
        <h3>Upload your Spots</h3>
        <p>Upload your map with at least 30 spots to get started. Feel free to add more for better recommendations.</p>
        <MPMessage v-if="errorMessage" version="error">
          {{ errorMessage }}
        </MPMessage>
        <div class="onboarding-step-actions">
          <div class="file-upload-wrapper">
            <label for="file">
              <i class="ri-add-circle-line"/>
              &nbsp;{{ formModel.file?.name ? formModel.file.name : 'Add PDF, XLXS, XLS or DOC file.'}}
            </label>
            <input id="file" type="file" name="pdf-file" accept=".pdf" @change="handleFileUpload">
          </div>
          <hr>
          <small>Use Google Maps list</small>
          <div class="google-maps-link-wrapper">
            <MPImg src="/media/images/google-maps-logo.png" class="maps-logo"/>
            <MPInput
              v-model="formModel.url"
              label="Google maps link"
              name="url"
              placeholder="Add URL"
              help-text="Password? Remember to have your list unprotected"
              hide-label
            />
          </div>
          <MPButton rounded label="Next" version="info" size="large" @click="handleStepValidation('You need to provide a file or a shared google map link (https://maps.app.goo.gl/1234)')"/>
        </div>
      </div>
      <div v-if="stepper.isCurrent('terms')" class="onboarding-step">
        <span>Creator Onboarding</span>
        <h3>Accept Terms & Conditions</h3>
        <p>Please review and accept the required documents to finalize your setup and publish your map.</p>
        <div class="onboarding-step-actions">
          <ul class="terms">
            <li>
            <label for="terms-checkbox">
              <input id="terms-checkbox" v-model="termsModel.termsAccepted" type="checkbox" required>
              I have read and agree to the <a target="_blank" href="https://mappr.com/info/creator-terms">Terms and Conditions (v1)</a> of Mapprexplore SL.
            </label>
            </li>
            <li>
            <label for="copyright-checkbox">
              <input id="copyright-checkbox" v-model="termsModel.copyrightAccepted" type="checkbox" required>
              I acknowledge and accept the <a target="_blank" href="https://mappr.com/info/copyright">Copyright Policy(v1)</a> of Mapprexplore SL.
            </label>
            </li>
            <li>
            <label for="compensation-terms">
              <input id="compensation-terms" v-model="termsModel.compensationAccepted" type="checkbox" required>
              I agree to the <a target="_blank" href="https://mappr.com/info/creator-compensation">Creator Compensation Terms (v1)</a> provided by Mapprexplore SL.
            </label>
            </li>
          </ul>
          <div>
            <MPButton rounded label="Back" version="secondary" size="large"  @click="stepper.goToPrevious"/>
            &nbsp;
            <MPButton rounded type="submit" label="Next" version="info" size="large" :is-loading="isLoading"/>
          </div>
        </div>
      </div>
      <div v-if="stepper.isCurrent('complete')" class="onboarding-step">
        <span>Creator Onboarding</span>
        <h3>Onboarding complete</h3>
        <p>Congratulations! Your map is under review and being prepared. We’ll email you once it’s ready to publish.</p>
        <MPImg src="/media/images/creator-onboarding-complete.png" class="creator-onboarding-img"/>
        <NuxtLinkLocale to="/explore" class="onboarding-step-actions">
          <MPButton rounded version="info" size="large" label="Explore mappr"/>
        </NuxtLinkLocale>
      </div>
    </form>
  </div>
</template>

<style lang="postcss" scoped>
@import '@/assets/css/typography.pcss';
.creator-onboarding {
  margin: 0 0 1rem 0;
  max-width: 28rem;
  padding: 0 2rem;
}
.onboarding-step {
  display: grid;
  align-items: center;
  text-align: center;
  span {
    color: var(--color-indigo-400);
    @mixin typeface-summary;
  }
  h3 {
    margin: 0.5rem 0;
  }
  p {
    margin: 0.5rem 0;
  }
}

hr {
  width: 100%;
  border-bottom: 1px solid var(--color-slate-200);
  border-image: linear-gradient(to right, var(--color-slate-200) 0%, transparent 50%, var(--color-slate-200) 100%) 1;
  border-top: 0;
  margin: 0.75rem 0;
  overflow: visible;
  &:after {
    content: 'or';
    display: inline-block;
    position: relative;
    bottom: -0.5rem;
    margin: 0 0.5rem -1rem;
    color: var(--color-slate-400);
  }
}

.onboarding-step-actions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  button {
    max-width: fit-content;
  }
}

.creator-onboarding-img {
  margin: 0 auto;
  max-width: 15rem;
}

.google-maps-link-wrapper {
  position: relative;
  img {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    width: 1.75rem;
    height: 1.75rem;
    z-index: 10;
  }
  &:deep(input) {
    padding-left: 2.75rem;
  }
}

.file-upload-wrapper {
  position: relative;
  width: 100%;
  margin: 1rem 0 -0.5rem;
  #file {
    border: 1px dashed var(--color-slate-200);
    padding: 2.5rem 0;
    width: 100%;
    margin: 0.5rem 0 0;
    background: var(--color-slate-50);
    border-radius: 0.5rem;
    align-items: center;
    text-align: center;
    color: var(--color-slate-50);
    &::file-selector-button {
      display: none;
    }
  }
  label {
    position: absolute;
    top: 50%;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -25%);
    color: var(--color-slate-400);
  }
}

.onboarding-dots {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0 0 1.5rem;
  li {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background: var(--color-slate-100);
    border: 1px solid var(--color-slate-200);
    margin: 0 0.5rem;
    &.active {
      background: var(--color-green-300);
      border: 1px solid var(--color-green-400);
    }
  }
  li:last-child {
    display: none;
  }
}

.terms {
  list-style: none;
  padding: 0 1rem;
  margin: 0;
  label {
    display: block;
    padding-left: 1rem;
    text-indent: -1.5rem;
  }
  li {
    align-items: left;
    text-align: left;
    margin: 1rem 0;
  }
  a {
    text-decoration: underline;
  }
}
</style>
